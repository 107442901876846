<template>
    <div class="app-main__inner">
        <div class="container mb-3">
            <div class="row">
                <div class="col-md-12 bg-white p-3">
                    <div class="row v-bill-1">
                        <div data-cy='year-interval' class="col-md-6 mb-2 md:mb-0 flex align-items-center justify-between">
                            <div class="font-light">{{ $t('paymentInterval') }}:
                                <span class="text-gray-900 font-semibold" v-if="userPaymentInterval === 1">{{ $t('monthly') }}</span>
                                <span class="text-gray-900 font-semibold" v-if="userPaymentInterval === 0">{{ $t('yearly') }}</span>
                            </div>
                            <div>
                                <button @click="switchYearly" class="btn border btn-border-all text-blue-900 hover:text-blue-900 cursor-not-allowed" v-if="userPaymentInterval === 1">
                                    <div class="flex items-center m-auto" v-if="processingYearly">
                                        <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                                        <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                        </svg>
                                        {{ $t('processing') }}
                                    </div>
                                    <span class="m-auto" v-else>{{ $t('switchYear') }}</span>
                                </button>
                            </div>
                        </div>
                        <div class="col-md-6 mb-2 md:mb-0 flex align-items-center justify-between">
                            <div data-cy='payment-type' class="font-light">{{ $t('paymentType') }}:
                                <span class="font-bold link-theme-color text-xs" v-if="GET_PAYMENT_TYPE !== null && GET_PAYMENT_TYPE === 0">{{ $t('invoicing') }}</span>
                                <span class="font-bold link-theme-color text-xs" v-if="GET_PAYMENT_TYPE !== null && GET_PAYMENT_TYPE === 1">{{ $t('creditCard') }}</span>
                            </div>
                            <!-- <div>
                                <button v-if="GET_PAYMENT_TYPE !== null && GET_PAYMENT_TYPE === 0" @click="getUserInitializePayment" class="btn btn-border-all border border-blue-900 text-blue-900 hover:text-blue-900 cursor-not-allowed" :disabled="processingCard || (GET_PAYMENT_TYPE !== null && GET_PAYMENT_TYPE === 1)">
                                    <div class="flex items-center m-auto" v-if="processingCard">
                                        <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                                        <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                        </svg>
                                        {{ $t('processing') }}
                                    </div>
                                    <span class="m-auto" v-else>{{ $t('payWithCard') }}</span>
                                </button>
                                <button @click="switchToInvoice" v-if="GET_PAYMENT_TYPE !== null && GET_PAYMENT_TYPE === 1" class="btn btn-border-all border border-blue-900 text-blue-900 hover:text-blue-900 cursor-not-allowed" :disabled="processingInvoice">
                                    <div class="flex items-center m-auto" v-if="processingInvoice">
                                        <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                                        <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                        </svg>
                                        {{ $t('processing') }}
                                    </div>
                                    <span class="m-auto" v-else>{{ $t('switchInvoice') }}</span>
                                </button>
                            </div> -->
                        </div>
                    </div>
                    <div data-cy='billing-form-within' class="row space-y-1">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="inputCompanyName" class="text-xs mb-1 col-form-label font-bold  whitespace-nowrap">{{ $t('companyName') }}</label>
                                <div>
                                    <input data-cy='billing-name' type="text" v-model="billing.name" class="h-c33 form-control  font-light text-xs input-shadow-box">
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="city" class="col-form-label font-bold whitespace-nowrap text-xs mb-1">{{ $t('city') }}</label>
                                <div>
                                    <input data-cy='billing-company-city' type="text" v-model="billing.company.city" class="h-c33 form-control  font-light text-xs input-shadow-box">
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="inputCompanyNumber" class="col-form-label font-bold whitespace-nowrap text-xs mb-1">{{ $t('companyNumber') }}</label>
                                <div>
                                    <input data-cy='billing-company-id' type="text" v-model="billing.company.company_id" class="h-c33 form-control  font-light text-xs input-shadow-box">
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="city" class="col-form-label font-bold whitespace-nowrap text-xs mb-1">{{ $t('phoneNumber') }}</label>
                                <div>
                                    <input data-cy='billing-company-phone' type="text" v-model="billing.company.phone_mobile" class="h-c33 form-control  font-light text-xs input-shadow-box">
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="inputCompanyAddress" class="col-form-label font-bold whitespace-nowrap text-xs mb-1">{{ $t('billingAddress') }}</label>
                                <div>
                                    <input data-cy='billing-company-address' type="text" v-model="billing.company.address_line_one" class="h-c33 form-control  font-light text-xs input-shadow-box">
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="email" class="col-form-label font-bold whitespace-nowrap text-xs mb-1">{{ $t('email')}}</label>
                                <div>
                                    <input data-cy='billing-company-email' type="email" v-model="billing.company.email" class="h-c33 form-control  font-light text-xs input-shadow-box">
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="inputCompanyZipcode" class="col-form-label font-bold whitespace-nowrap text-xs mb-1">{{ $t('zipCode') }}</label>
                                <div>
                                    <input data-cy='billing-company-zip' type="text" v-model="billing.company.zip_code" class="h-c33 form-control  font-light text-xs input-shadow-box">
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="invoiceEmail" class="col-form-label font-bold whitespace-nowrap text-xs">{{ $t('invoiceEmail') }}</label>
                                <div>
                                    <input data-cy='billing-company-invoice-email' type="email" v-model="billing.company.billing_email" class="h-c33 form-control  font-light text-xs input-shadow-box">
                                </div>
                            </div>
                        </div>
                        <div class="text-center mt-3 col-md-12">
                            <span>
                                <button data-cy='billing-btn-save' @click="updateUserCompany" :disabled="updating" class="btn btn-theme-custom btn-primary btn-wide cursor-not-allowed v-bill-2">
                                    <span v-if="updating" class="flex align-items-center space-x-3">
                                        <svg class="animate-spin mr-2 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                                        <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                        </svg>
                                        {{ $t('updating') }}
                                    </span>
                                    <span v-else>{{ $t('update') }}</span>
                                </button>
                            </span>
                        </div>
                    </div>
                    <div class="row">
                    </div>
                </div>
                <div class="col-md-12 mb-10"> <!--pl-5 pr-5-->
                    <div class="mb-5 mt-4">
                        <h2 data-cy='billing-table-head' class="font-bold link-theme-color text-sm mb-4">{{ $t('billingHistory') }}</h2>
                        <div class="table-responsive">
                            <table v-loading='loading' style="width: 100%;" id="exampledestroy" class="table table-hover table-striped table-bordered">
                                <thead>
                                    <tr>
                                        <th class="text-xs">{{ $t('companyName') }}</th>
                                        <th class="text-xs"><div class="d-flex justify-content-between">{{ $t('amountDue') }}<span><i @click="sortPriceAscending" class="lnr-arrow-up cursor-pointer"></i><i @click="sortPriceDescending" class="lnr-arrow-down cursor-pointer"></i></span></div></th>
                                        <th class="text-xs"><div class="d-flex justify-content-between">{{ $t('date') }}<span><i @click="sortDateAscending" class="lnr-arrow-up cursor-pointer"></i><i @click="sortDateDescending" class="lnr-arrow-down cursor-pointer"></i></span></div></th>
                                        <th class="text-xs"><div class="d-flex justify-content-between">{{ $t('dueDate')}}<span><i @click="sortDueDateAscending" class="lnr-arrow-up cursor-pointer"></i><i @click="sortDueDateDescending" class="lnr-arrow-down cursor-pointer"></i></span></div></th>
                                        <th class="text-xs">{{ $t('status') }}</th>
                                        <th class="text-xs v-bill-3">{{ $t('file') }}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(invoice, i) in GET_BILLING_HISTORY.Invoices" :key="i">
                                        <td class="text-xs font-light">{{ invoice.CustomerName }}</td>
                                        <td class="text-xs font-light">{{ invoice.Total }} SEK</td>
                                        <td class="text-xs font-light">{{ invoice.InvoiceDate }}</td>
                                        <td class="text-xs font-light">{{ invoice.DueDate }}</td>
                                        <td class="text-xs font-light">
                                            <span v-if="invoice.Balance === 0" class="font-semibold text-green-500">{{ $t('paid') }}</span>
                                            <span v-else class="font-semibold text-red-500">{{ $t('unpaid') }}</span>
                                        </td>
                                        <td>
                                            <DownloadTableManager :data='invoice' />
                                        </td>
                                    </tr>
                                    <!-- <tr v-if="GET_BILLING_HISTORY.Invoices !== undefined && GET_BILLING_HISTORY.Invoices.length < 1" class="odd text-center"><td colspan="5" class="dataTables_empty text-xs" valign="top">{{ $t('noDataTable') }}</td></tr> -->
                                </tbody>
                            </table>
                        </div>
                        <div class="float-right mb-5" v-if="GET_BILLING_HISTORY.MetaInformation">
                            <paginate
                            :page-count="GET_BILLING_HISTORY.MetaInformation['@TotalPages']"
                            :click-handler="changePageNumber"
                            prev-text="<"
                            next-text=">"
                            :container-class="'pagination'">
                            </paginate>
                        </div>
                    </div>
                </div>
                <!-- <div class="col-md-1"></div> -->
            </div>
        </div>


        <el-dialog
        :visible.sync="paymentCardModal"
        width="40%"
        :handleClose="handleClose"
        center>
        <div class="container">
            <div class="row">
                <div id="card-element" class="col-md-12">

                </div>
            </div>
        </div>
        </el-dialog>

        <Notification />
    </div>
</template>

<script>
    import Vue from 'vue'
    import { mapGetters } from 'vuex'
    import Paginate from 'vuejs-paginate'
    import DownloadTableManager from './../../customComponent/DownloadTableManager'
    import { Dialog } from 'element-ui'

    Vue.use(Dialog)

    export default {
        name : 'billing-dashboard',
        components : {
            Notification : () => import('./../../customComponent/NotificationAlert'),
            paginate : Paginate,
            DownloadTableManager,
            // Dialog
        },
        data () {
            return {
                PaymentType : null,
                billing : {
                    name : '',
                    company : {
                        company_id : '',
                        address_line_one : '',
                        zip_code : '',
                        city : '',
                        phone_mobile : '',
                        email : '',
                        invoiceEmail : '',
                        billing_email : '',
                    },
                },
                history : [],
                copyHistory : [],
                userPaymentInterval : null,
                updating : false,
                paymentCardModal : false,
                processingCard : false,
                processingInvoice : false,
                processingYearly : false,
                loading : true
            }
        },
        computed : {
            ...mapGetters({
                USER_TOKEN : 'auth/USER_TOKEN',
                USER_REFRESH : 'auth/USER_REFRESH',
                GET_USER : 'customer/GET_USER',
                GET_USER_COMPANY_ID : 'customer/GET_USER_COMPANY_ID',
                GET_COMPANY_BILLING : 'customer/GET_COMPANY_BILLING',
                GET_PAYMENT_TYPE : 'customer/GET_PAYMENT_TYPE',
                GET_BILLING_HISTORY : 'customer/GET_BILLING_HISTORY'
            })
        },
        mounted () {
            if (this.GET_COMPANY_BILLING !== null && this.GET_COMPANY_BILLING.name) {
                this.billing.name = this.GET_COMPANY_BILLING.name
                this.billing.company.company_id = this.GET_COMPANY_BILLING.company_id
                this.billing.company.email = this.GET_COMPANY_BILLING.email
                this.billing.company.city = this.GET_COMPANY_BILLING.city
                this.billing.company.phone_mobile = this.GET_COMPANY_BILLING.phone_mobile
                this.billing.company.address_line_one = this.GET_COMPANY_BILLING.address_line_one
                this.billing.company.zip_code = this.GET_COMPANY_BILLING.zip_code

                this.billing.company.billing_email = this.GET_COMPANY_BILLING.billing_email

                if (this.GET_COMPANY_BILLING.company) {
                    this.billing.company = this.GET_COMPANY_BILLING.company
                }

                this.PaymentType = this.GET_PAYMENT_TYPE

                if (this.GET_USER) {
                    this.userPaymentInterval = this.GET_USER.contract_interval
                }
            } else {
                // Fetch user company
                if (this.GET_USER_COMPANY_ID) {
                    this.getUserCompany(this.GET_USER_COMPANY_ID)
                } else {
                    this.getAuthUser()
                }
            }
            if (this.GET_USER_COMPANY_ID) {
                this.getAuthUser(this.GET_USER_COMPANY_ID)
                this.getUserBillingHistory(this.GET_USER_COMPANY_ID)
            } else {
                this.getUserBillingHistory()
            }

            window.Bus.$emit('start-billing-tour')
        },
        methods : {
            changePageNumber (pageNum) {
                const URL = this.GET_USER_COMPANY_ID ? this.$services.endpoints.GET_BILLING_HISTORY + `${this.GET_USER_COMPANY_ID}/?page=${pageNum}` : this.$services.endpoints.GET_BILLING_HISTORY + `?page=${pageNum}`
                this.$store.dispatch('customer/getUserBillingHistory', URL)
                .then(_ => {})
                .catch((err) => {
                    if (err.response.status === 400) {
                        if (err.response.data.code === 401) {
                            this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
                            .then(_ => {
                                this.changePageNumber(pageNum)
                            })
                            .catch(_ => {
                                this.$store.commit('auth/CLEAR_AUTH_USER', null)
                                window.Bus.$emit('sign-out')
                            })
                        }
                    }
                })
            },
            sortDateAscending () {
                this.$store.commit('customer/SORT_DATE_ASC')
            },
            sortDateDescending () {
                this.$store.commit('customer/SORT_DATE_DESC')
            },
            sortDueDateAscending () {
                this.$store.commit('customer/SORT_DUE_DATE_ASC')
            },
            sortDueDateDescending () {
                this.$store.commit('customer/SORT_DUE_DATE_DESC')
            },
            sortPriceAscending () {
                this.$store.commit('customer/SORT_PRICE_ASC')
            },
            sortPriceDescending () {
                this.$store.commit('customer/SORT_PRICE_DESC')
            },
            handleClose () {
                this.paymentCardModal = false
            },
            runStripeScript () {
                const stripeScript = document.createElement('script')
                stripeScript.setAttribute('src', 'https://js.stripe.com/v3/')
                stripeScript.async = true
                stripeScript.defer = true
                document.head.appendChild(stripeScript)

                stripeScript.onload = () => {
                    // console.log('script loaded')
                }
            },
            proceedCheckout (id) {
                const stripe = Stripe(process.env.VUE_APP_STRIPE_KEY)
                stripe.redirectToCheckout({
                    // Make the id field from the Checkout Session creation API response
                    // available to this file, so you can provide it as argument here
                    // instead of the {{CHECKOUT_SESSION_ID}} placeholder.
                    sessionId: id,
                }).then(function (_) {})
            },
            switchToInvoice () {
                this.processingInvoice = true
                const URL = this.GET_USER_COMPANY_ID ? this.$services.endpoints.GET_USER_SWITCH_INVOICE + `${this.GET_USER_COMPANY_ID}/` : this.$services.endpoints.GET_USER_SWITCH_INVOICE
                this.$store.dispatch('customer/switchToInvoice', URL)
                .then(res => {
                    this.processingInvoice = false
                    // console.log(res)
                    this.$services.helpers.notification(this.$t('switchSuccess'), 'success', this)
                    if (this.GET_USER_COMPANY_ID) {
                        this.getUserCompany(this.GET_USER_COMPANY_ID)
                    } else {
                        this.getUserCompany()
                    }
                }).catch((err) => {
                    this.processingInvoice = false
                    if (err.response.status === 400) {
                        if (err.response.data.code === 401) {
                            this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
                            .then(_ => {
                                if (this.GET_USER_COMPANY_ID) {
                                    this.getUserCompany(this.GET_USER_COMPANY_ID)
                                } else {
                                    this.getUserCompany()
                                }
                            })
                            .catch(_ => {
                                this.$store.commit('auth/CLEAR_AUTH_USER', null)
                                window.Bus.$emit('sign-out')
                            })
                        }
                        this.$services.helpers.notification(err.response.data.data, 'error', this)
                    }
                })
            },
            switchYearly () {
                this.processingYearly = true
                const URL = this.GET_USER_COMPANY_ID ? this.$services.endpoints.GET_USER_SWITCH_YEARLY + `${this.GET_USER_COMPANY_ID}/` : this.$services.endpoints.GET_USER_SWITCH_YEARLY
                this.$store.dispatch('customer/switchToYearly', URL)
                .then(res => {
                    this.processingYearly = false
                    this.$services.helpers.notification(this.$t('switchYearlySuccess'), 'success', this)
                })
                .catch(err => {
                    this.processingYearly = false
                    if (err.response.status === 400) {
                        if (err.response.data.code === 401) {
                            this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
                            .then(_ => {
                                this.switchYearly()
                            })
                            .catch(_ => {
                                this.$store.commit('auth/CLEAR_AUTH_USER', null)
                                window.Bus.$emit('sign-out')
                            })
                        }
                    }
                })
            },
            getUserBillingHistory (userID = null) {
                const URL = userID ? this.$services.endpoints.GET_BILLING_HISTORY + `${userID}/` : this.$services.endpoints.GET_BILLING_HISTORY
                this.$store.dispatch('customer/getUserBillingHistory', URL)
                .then(res => {
                    this.loading = false
                    // console.log('Billing history:', res)
                }).catch((err) => {
                    this.loading = false
                    if (err.response.status === 400) {
                        if (err.response.data.code === 401) {
                            this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
                            .then(_ => {
                                if (this.GET_USER_COMPANY_ID) {
                                    this.getUserBillingHistory(this.GET_USER_COMPANY_ID)
                                } else {
                                    this.getUserBillingHistory()
                                }
                            })
                            .catch(_ => {
                                this.$store.commit('auth/CLEAR_AUTH_USER', null)
                                window.Bus.$emit('sign-out')
                            })
                        }
                    }
                })
            },
            // getUserPaymentHistory (userID) {
            //     const URL = userID ? this.$services.endpoints.GET_PAYMENT_HISTORY + `${userID}/` : this.$services.endpoints.GET_PAYMENT_HISTORY
            //     this.$services.axios.defaults.headers.common.Authorization = `Bearer ${this.USER_TOKEN}`
            //     this.$services.axios.get(URL)
            //     .then(_ => {
            //     }).catch((err) => {
            //         if (err.response.status === 401) {
            //             this.$store.commit('auth/CLEAR_AUTH_USER', null)
            //             window.Bus.$emit('sign-out')
            //         }
            //     })
            // },
            getUserInitializePayment () {
                if (this.GET_PAYMENT_TYPE === 0) {
                    this.processingCard = true
                    const URL = this.GET_USER_COMPANY_ID ? this.$services.endpoints.GET_USER_INITIALIZE_PAYMENT + `${this.GET_USER_COMPANY_ID}/` : this.$services.endpoints.GET_USER_INITIALIZE_PAYMENT
                    this.$store.dispatch('customer/getUserInitializePayment', URL)
                    .then(res => {
                        this.processingCard = false
                        window.localStorage.setItem('stripe_session', res.data.data.session_id)
                        this.proceedCheckout(res.data.data.session_id)
                    }).catch((err) => {
                        this.processingCard = false
                        if (err.response.status === 400) {
                            if (err.response.data.code === 401) {
                                this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
                                .then(_ => {
                                    this.getUserInitializePayment()
                                })
                                .catch(_ => {
                                    this.$store.commit('auth/CLEAR_AUTH_USER', null)
                                    window.Bus.$emit('sign-out')
                                })
                            }
                            this.$services.helpers.notification(err.response.data.data, 'error', this)
                        }
                    })
                }
                return false
            },
            getUserCompany (userID) {
                const URL = userID ? this.$services.endpoints.GET_USER_COMPANY + `${userID}/` : this.$services.endpoints.GET_USER_COMPANY
                this.$store.dispatch('customer/getUserCompany', URL)
                .then(res => {
                    if (res.data.success) {
                        this.billing.company = res.data.data
                        this.billing.name = res.data.data.name
                    }
                }).catch(err => {
                    if (err.response.status === 400) {
                        if (err.response.data.code === 401) {
                            this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
                            .then(_ => {
                                if (this.GET_USER_COMPANY_ID) {
                                    this.getUserCompany(this.GET_USER_COMPANY_ID)
                                } else {
                                    this.getUserCompany()
                                }
                            })
                            .catch(_ => {
                                this.$store.commit('auth/CLEAR_AUTH_USER', null)
                                window.Bus.$emit('sign-out')
                            })
                        }
                    }
                })
            },
            getAuthUser (userID = null) {
                const URL = userID ? this.$services.endpoints.GET_USER + `${userID}/` : this.$services.endpoints.GET_USER
                this.$store.dispatch('customer/getAuthUser', { URL })
                .then(res => {
                    if (res.data.success) {
                        if (res.data.data.user.associates) {
                            this.$store.commit('customer/SET_USER_COMPANY_PAYMENT_TYPE', res.data.data.user.associates[0].payment_method)
                            this.billing = res.data.data.user.associates[0].company
                        } else {
                            this.$store.commit('customer/SET_USER_COMPANY_BILLING', res.data.data.user.company)
                            this.$store.commit('customer/SET_USER_COMPANY_PAYMENT_TYPE', res.data.data.user.payment_method)
                            if (res.data.data.user.company.address === null) {
                                this.billing.name = res.data.data.user.company.name
                                this.billing.company.company_id = res.data.data.user.company.company_id
                                this.billing.company.email = res.data.data.user.company.email
                            } else {
                                this.billing.name = res.data.data.user.company.name
                                this.billing.company = res.data.data.user.company
                            }
                        }
                        this.userPaymentInterval = this.GET_USER.contract_interval
                    }
                }).catch(err => {
                    if (err.response.status === 400) {
                        if (err.response.data.code === 401) {
                            this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
                            .then(_ => {
                                this.getAuthUser()
                            })
                            .catch(_ => {
                                this.$store.commit('auth/CLEAR_AUTH_USER', null)
                                window.Bus.$emit('sign-out')
                            })
                        }
                    }
                })
            },
            updateUserCompany () {
                if (this.billing.name === '' || this.billing.company.company_id === null) {
                    this.$services.helpers.notification(this.$t('requireCompanyNumber'), 'error', this)
                    return false
                }
                if (this.billing.company.address_line_one === '') {
                    this.$services.helpers.notification(this.$t('requireCompanyAddress'), 'error', this)
                    return false
                }
                if (this.validateCompanyNumber(this.billing.company.company_id)) {
                    this.$services.helpers.notification(this.$t('invalidCompany'), 'error', this)
                    return false
                }
                if (!this.$services.helpers.validateEmailAddress(this.billing.company.email)) {
                    this.$services.helpers.notification(this.$t('invalidEmail'), 'error', this)
                    return false
                }

                if (this.billing.company.phone_mobile === '') {
                    this.$services.helpers.notification(this.$t('requirePhonenumber'), 'error', this)
                    return false
                }
                if (this.billing.company.zip_code === '') {
                    this.$services.helpers.notification(this.$t('requireZipCode'), 'error', this)
                    return false
                }
                if (this.billing.company.city === '') {
                    this.$services.helpers.notification(this.$t('requireCity'), 'error', this)
                    return false
                }
                this.updating = true
                const URL = this.GET_USER_COMPANY_ID ? this.$services.endpoints.GET_USER_COMPANY + `${this.GET_USER_COMPANY_ID}/` : this.$services.endpoints.GET_USER_COMPANY
                const payload = {
                    name : this.billing.name,
                    company_id : this.billing.company.company_id,
                    city : this.billing.company.city,
                    phone_mobile : this.billing.company.phone_mobile,
                    zip_code : this.billing.company.zip_code,
                    address_line_one : this.billing.company.address_line_one,
                    email : this.billing.company.email,
                    invoice_email: this.billing.company.invoiceEmail,
                    billing_email: this.billing.company.billing_email
                }
                this.$store.dispatch('customer/updateUserCompany', { data : payload, URL: URL })
                .then(res => {
                    this.updating = false
                    if (res.data.success) {
                        this.$services.helpers.notification(this.$t('successCompanyUpdated'), 'success', this)
                        this.getAuthUser(this.GET_USER_COMPANY_ID)
                    }
                }).catch(err => {
                    this.updating = false
                    if (err.response.status === 400) {
                        if (err.response.data.code === 401) {
                            this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
                            .then(_ => {
                            })
                            .catch(_ => {
                                this.$store.commit('auth/CLEAR_AUTH_USER', null)
                                window.Bus.$emit('sign-out')
                            })
                        }
                    }
                })
            },
            validateCompanyNumber (number) {
                if (!number.includes('-')) {
                    return true
                }
                if (number.split('-')[0].length === 6 && number.split('-')[1].length === 4) {
                    return false
                }
                return true
            }
        }
    }
</script>

<style lang="scss" scoped>
@import "./../../assets/variables.scss";
.custom-btn-pay{
    background-color: #F167CA !important;
    border-color: #F167CA !important;
    font-weight: 800;
}
.custom-card-section{
    box-shadow: 0 0.46875rem 2.1875rem rgba(4,9,20,0.03),0 0.9375rem 1.40625rem rgba(4,9,20,0.03),0 0.25rem 0.53125rem rgba(4,9,20,0.05),0 0.125rem 0.1875rem rgba(4,9,20,0.03);
    border-width: 0;
    transition: all .2s;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(26,54,126,0.125);
}
.custom-save-btn{
    background-color: #2CDCA7 !important;
    border-color: #2CDCA7 !important;
    font-weight: 800;
}
.override-form-field{
    border: 0;
    border-bottom: 2px solid rgb(64, 138, 235);
    box-shadow: none;
    background-color: #fff !important;
    border-radius: unset !important;
}
.col-form-label {
    padding-top: 1rem !important;
    // font-size: 14px !important;
}
.form-group{
    margin-bottom: 0.4rem !important;
}
.form-input{
    font-size: 0.8rem !important;
    padding: 0.2rem 0.75rem !important;
}
.col-form-label{
    padding-bottom: unset !important;
    padding-top: 0.3rem !important;
}
.app-main .app-main__inner{
    padding-top: 20px !important;
}
.table td{
    padding: 0.1rem 0.55rem !important;
}
.form-control{
    border: 1px solid $form-border !important;
    // height: unset !important;
    padding: .175rem .75rem !important;
}
.form-control:focus{
    box-shadow: unset !important;
    border: 1px solid $theme-primary-color !important;
}
.btn-border-all{
    border: 1px solid $theme-secondary-color !important;
    color: $theme-secondary-color !important;
}
.table-responsive::-webkit-scrollbar {
  display: none !important;   /* width of the entire scrollbar */
}
.table-responsive::-webkit-scrollbar-track {
  background: $form-border;        /* color of the tracking area */
}
</style>
